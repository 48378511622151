import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet'

function App() {
  const title = "Feature Engineering as a Service"
  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Row>
          <Col xs="12">
            <h1>Feature Engineering as a Service</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
